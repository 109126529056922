* {
  font-family: Verdana;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

ol,
ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  background-color: #CCD3D9;
}
